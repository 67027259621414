<template>
  <div class="add-banner">
    <div class="title">{{ title }}</div>
    <div class="cell">
      <el-form
        size="mini"
        style="width: 500px"
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="轮播名称" prop="name">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <Upload :imageUrl="imgList" :ids="ids" :urlList="urlList"></Upload>

          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <div style="font-size: 12px; color: #ee4d2d">
            *建议轮播图片尺寸900x270
          </div>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form.jump_link"></el-input>
        </el-form-item>

        <el-form-item label="排序">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="handleBack" plain>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import Upload from "@/components/AdminUpload2";
import { bannerAdd, bannerEdit } from "@/api/admin.js";
export default {
  components: {
    Upload,
  },
  data() {
    return {
      form: {
        id: 0,
        title: "",
        file_id: "",
        jump_link: "",
        sort: "",
        status: 1,
      },
      options: [],
      props: {
        value: "category_id",
        label: "name",
        checkStrictly: true,
        emitPath: false,
      },
      rules: {
        title: [{ required: true, message: "请输入轮播名称", trigger: "blur" }],
      },
      imgList: [],
      urlList: [],
      ids: [],
      title: "新增",

      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  created() {
    if (sessionStorage.ADMIN_BANNER_INFO) {
      this.title = "编辑";
      this.form = JSON.parse(sessionStorage.ADMIN_BANNER_INFO);
      if (this.form.image) {
        this.imgList.push(this.form.image);
        this.ids.push(this.form.file_id);
      }

      delete this.form.list;
      delete this.form.image;
    }
  },
  methods: {
    onSubmit() {
      this.form.file_id = this.ids.join(",");
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id == 0) {
            bannerAdd(this.form).then((res) => {
              if (res.code == 1) {
                this.$message.success("添加轮播成功");
                this.handleBack();
              }
            });
          } else {
            bannerEdit(this.form).then((res) => {
              if (res.code == 1) {
                this.$message.success("编辑轮播成功");
                this.handleBack();
              }
            });
          }
        }
      });
    },
    handleBack() {
      // this.$router.replace("adminBanner");
   this.$router.back()
    },
    handleChange() {},

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less">
.add-banner {
  .title {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .cell {
    background: #fff;
    padding: 20px;
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-radio__label {
  font-size: 12px;
}
</style>